<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Returned Leads')" :description="$t('Total')+' '+pagination.totalRecords+' leads.'"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{$t('Bulk Action')}}</option>
                        <option value="to_marketplace">{{$t('To Marketplace')}}</option>
                        <option value="delete">{{$t('Delete')}}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{$t('Apply')}}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="r_leads_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== 'all' || reasonFilter !== '' ? 'primary':'')"
                            :title="'Leads '+ $t('Filter') ">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="8">
                                <label class="overline-title overline-title-alt" for="status_filter">{{$t('Status')}}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="all">{{$t('All')}}</option>
                                  <option value="3">{{$t('Pending Review')}}</option>
                                  <option value="2">{{$t('Return Rejected')}}</option>
                                  <option value="1">{{$t('Return Accepted')}}</option>
                                </select>
                              </column>
                              <column default="12" class="mt-2">
                                <label class="overline-title overline-title-alt" for="reason_filter">{{$t('Filter by reason')}}</label>
                                <select class="form-control form-control-sm" id="reason_filter" v-model="reasonFilter">
                                  <option value="">{{$t('All')}}</option>
                                  <option v-for="reason in leadReturnReasons" :key="'rr'+reason.id" :value="reason.id">{{ $t(reason.reason) }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{$t('Filter')}}</nk-button>
                            <a href="javascript:;" v-on:click="[statusFilter='all',reasonFilter='',getLeads(true)]">{{$t('Reset Filter')}}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{$t('Order')}}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">{{$t('ASC')}}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">{{$t('DESC')}}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search advisor name, lead #, lead ID')"
                  :is-active="searchBarActive"
                  @do-search="searchAdvisors"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="rl_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">Lead#</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{$t('Returned by')}}</span>
                    </table-col>
                    <table-col break-point="mb">{{$t('Return date')}}</table-col>
                    <table-col break-point="md">{{$t('Status')}}</table-col>
                    <table-col break-point="lg">{{$t('Reason')}}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads" :key="'ld'+lead.id">
                    <table-col has-check-box>
                      <b-check-box :id="'lead'+lead.id" v-model="lead.selected" :key="'cb'+lead.id"></b-check-box>
                    </table-col>
                    <table-col break-point="md">{{ lead.lead_number }}</table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :user-email="lead.email" :image="lead.dp" :initials="lead.initials">
                        <template v-slot:more_info>
                          <div class="d-block d-md-none"><b>Lead #</b> {{ lead.lead_number}}</div>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <span class="tb-lead">{{ lead.return_date }}</span>
                      <span>{{ lead.return_time_passed }} {{$t('ago')}}</span>
                    </table-col>
                    <table-col break-point="md">
                      <span v-if="lead.return_status === 1" class="text-success">{{$t('Returned')}}</span>
                      <span v-else-if="lead.return_status === 2" class="text-danger">{{$t('Rejected')}}</span>
                      <span v-else-if="lead.return_status === 3" class="text-muted">{{$t('Pending')}}</span>
                      <span v-else class="text-warning">{{$t('Unknown')}}</span>
                    </table-col>
                    <table-col break-point="lg">{{ $t(lead.reason) }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" text="View Details" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                            <list-item icon="ni-reply-fill" text="Respond"
                                       v-if="lead.return_status === 3"
                                       v-on:click="prepareLeadForResponse(lead)"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <respond-to-lead-return
        :is-modal-open="returnResponse.isModalOpen"
        :lead-data="returnResponse.leadData"
        @close-modal="setStatusModalOpenStatus(false)"
        @status-change="returnStatusChanged"></respond-to-lead-return>
  </page-template>
</template>

<script>
import {
  IonSpinner, onIonViewWillEnter,
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import useMarketplace from "@/views/admin/Leads/useMarketplace";
import useDeleteLeads from "@/views/admin/Leads/useDeleteLeads";
import RespondToLeadReturn from "@/views/admin/Leads/components/RespondToLeadReturn";
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    RespondToLeadReturn,
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
  },
  setup() {

    const router  = useRouter()
    const store   = useStore()
    const { updateTracker } = useDataChangeTracker()
    const leadStatuses = store.state.general_data.leads_statuses

    let searchFilter  = ref('');
    let statusFilter  = ref('3')
    let reasonFilter  = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let leads     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/leads/returned', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            reason: reasonFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=> getLeads(true))

    watch(selectAll, (n) => {
      for(let x in leads.value){
        leads.value[x].selected = n
      }
    })

    const {deleteRecords} = useDeleteLeads()
    const F_deleteRecords = async ( _records ) => {

      deleteRecords(_records, 'lead',(success) => {
        if(success){
          router.go(-1)
          // Remove the deleted entries from the advisors array
          for(let x in _records){
            for(let y in leads.value){
              if(leads.value[y].id === _records[x].id){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= _records.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }

    const { addToMarketplace } = useMarketplace()
    const F_addToMarketplace = ( _leads ) => {

      let ids = []
      for(let x in _leads){
        ids.push( _leads[x].id)
      }
      addToMarketplace(_leads, (success ) => {
        if(success){
          //updateTracker('admin', ['marketplace'])
          // Remove the selected leads from the leads array
          for(let x in ids){
            for(let y in leads.value){
              if(leads.value[y].id === ids[x]){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= ids.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedRecords.push( leads.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await F_deleteRecords(selectedRecords, 'lead')
      }
      else if( bulkSelectedAction.value === 'to_marketplace'){
        await F_addToMarketplace( selectedRecords )
      }
    }

    const returnResponse = reactive({
      isModalOpen: false,
      leadData: {
        return_id: 0,
        reason: '',
        explanation: '',
      },
      new_status: '',
      comment: '',
      lead: {},
    })

    const setStatusModalOpenStatus = (st) => returnResponse.isModalOpen = st
    const prepareLeadForResponse = (lead) => {
      returnResponse.lead = lead
      returnResponse.leadData.return_id = lead.return_id
      returnResponse.leadData.reason = lead.reason
      returnResponse.leadData.explanation = lead.explanation
      returnResponse.comment = lead.admin_comment
      setStatusModalOpenStatus(true)
    }

    const returnStatusChanged = (d) => {
      returnResponse.leadData.return_status = parseInt(d.new_status)
      returnResponse.leadData.admin_comment = d.comment
      returnResponse.lead.return_status = parseInt(d.new_status)
      setStatusModalOpenStatus(false)
    }
    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.admin.returned_leads, (n) => {
      if(n){
        updateTracker('admin', ['returned_leads'], false) // reset tracker
        getLeads(true)
      }
    })

    const leadReturnReasons = store.state.general_data.lead_return_reasons

    return {

      leadStatuses,
      leads,
      getLeads,
      loadingData,
      router,
      returnResponse,
      setStatusModalOpenStatus,
      prepareLeadForResponse,
      returnStatusChanged,
      leadReturnReasons,

      searchAdvisors,

      reasonFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      bulkSelectedAction,
      bulkAction,
    }
  }
});

</script>
